import {
	DocumentData,
	DocumentReference,
	GeoPoint,
	Timestamp,
	collection,
	getDocs,
	orderBy,
	query,
	where,
} from 'firebase/firestore';
import CustomBadge, { getStatusEnums, RCStatusEnum, RCType } from '../components/CustomBadge';
import { RCTypeInfo } from '../contexts/UserDataContext';
import { apiPost, functions } from '../helpers/axios';
import moment from 'moment';

export default class Delivery {
	delivery_id = '';

	delivery_datetime = new Timestamp(0, 0);

	delivered_by = '';

	delivery_location = new GeoPoint(0, 0);

	line_count = 0;

	order_count = 0;

	route_id = '';

	driver_id = '';

	status = 0;

	route_datetime = new Timestamp(0, 0);

	total_packages = 0;

	route_leg_id = '';

	customer_id = '';

	customer_name = '';

	feedback_stars = 0;

	feedback_comments = '';

	feedback_datetime = '';

	delivery_instructions = '';

	route_status = 0;

	confirmation_images = [];

	packages = [];

	confirm_signature = '';

	updated = false;

	constructor(delivery: RCTypeInfo['DELIVERY']) {
		this.delivery_id = delivery.delivery_id;
		this.delivery_datetime = delivery.delivery_datetime;
		this.delivered_by = delivery.delivered_by;
		this.delivery_location = delivery.delivery_location;
		this.line_count = delivery.line_count;
		this.order_count = delivery.order_count;
		this.route_id = delivery.route_id;
		this.driver_id = delivery.driver_id;
		this.route_leg_id = delivery.route_leg_id;
		this.status = delivery.status;
		this.customer_id = delivery.customer_id;
		this.customer_name = delivery.customer_name;

		this.route_datetime = delivery.route_datetime;
		this.total_packages = delivery.total_packages;
	}

	get data(): any {
		const status = getStatusEnums(this.status).title.delivery;

		try {
			let date;
			let time;
			date = this.delivery_datetime.seconds
				? moment(this.delivery_datetime.toDate()).format('DD MMMM, YYYY')
				: '';
			time = this.delivery_datetime.seconds
				? moment(this.delivery_datetime.toDate()).format('hh:mm A')
				: '';
			return {
				...this,
				status,
				badge: <CustomBadge type={RCType.DELIVERY} code={this.status} />,
				date,
				time,
				// date: '',
				// time: '',
			};
		} catch (error) {
			return {
				...this,
				status,
				badge: <CustomBadge type={RCType.DELIVERY} code={this.status} />,
				date: '',
				time: '',
				// date: '',
				// time: '',
			};
		}
	}

	updateFeedBack = async (stars: number, comments: string) => {
		await apiPost(functions.updateFeedBack, {
			tracking_ref: this.delivery_id,
			driver_id: this.driver_id,
			route_id: this.route_id,
			route_leg_id: this.route_leg_id,
			feedback_comments: comments,
			feedback_stars: stars,
			feedback_datetime: moment().toISOString(),
		});
		this.feedback_stars = stars;
		this.feedback_comments = comments;
		this.feedback_datetime = moment().toString();
	};
	updateDeliveryInstructions = async (instructions: string) => {
		await apiPost(functions.updateDeliveryInstructions, {
			tracking_ref: this.delivery_id,
			driver_id: this.driver_id,
			route_id: this.route_id,
			route_leg_id: this.route_leg_id,
			notes: instructions,
		});
		this.delivery_instructions = instructions;
	};

	getRouteLegData = async () => {
		const response = await apiPost(functions.retrieveRouteLegData, {
			tracking_ref: this.delivery_id,
		});

		this.delivery_instructions = response.data?.data?.driver_instructions;
		this.feedback_stars = response.data?.data?.feedback_stars || 0;
		this.feedback_comments = response.data?.data?.feedback_comments || '';
		this.feedback_datetime = moment(response.data?.data?.feedback_datetime || '').toString();
		this.route_status = response.data?.data?.route_status;
		this.confirmation_images = response.data?.data?.confirmation_images;
		this.packages = response.data?.data?.packages;
		this.confirm_signature = response.data?.data?.confirm_signature;
		this.updated = true;
	};
	getRouteLeg = async () => {
		const response = await apiPost(functions.retrieveRouteLeg, {
			driver_id: this.driver_id,
			route_id: this.route_id,
			route_leg_id: this.route_leg_id,
			tracking_ref: this.delivery_id,
		});

		this.delivery_instructions = response.data?.data?.notes || '';
	};

	static fetchCustomerDeliveries = async (
		customerRef: DocumentReference<DocumentData, DocumentData>,
		fetchEstimatedDatetime: (trackingRef: string) => Promise<Timestamp>,
	) => {
		const deliveryQuery = query(
			collection(customerRef, 'deliveries'),
			where('delivery_id', '!=', 'NGWINTDATA'),
			orderBy('delivery_id', 'desc'),
		);

		const deliveriesCollection = await getDocs(deliveryQuery);
		return await Promise.all(
			deliveriesCollection.docs.map(async (docSnapshot) => {
				const docData = docSnapshot.data();
				docData.delivery_id = docSnapshot.id;
				const { status } = docData;

				let routeDatetime: Timestamp;
				try {
					routeDatetime = new Timestamp(docData.delivery_datetime?.seconds, 0);
					if (
						![
							RCStatusEnum.ATTEMPTED,
							RCStatusEnum.DELIVERED,
							RCStatusEnum.PARTIAL,
							RCStatusEnum.REJECTED,
						].includes(status as number)
					) {
						routeDatetime = await fetchEstimatedDatetime(docData.delivery_id);
						docData.delivery_datetime = routeDatetime;
					}
				} catch (error) {
					routeDatetime = new Timestamp(0, 0);
				}

				return new Delivery({
					...(docData as RCTypeInfo['DELIVERY']),
					total_packages: docData.quantity_ordered || 0,
					route_datetime: docData.delivery_datetime,
				});
			}),
		);
	};
}
