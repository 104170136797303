import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import Icon from './icon/Icon';
import Input from './bootstrap/forms/Input';
// import Modal, { ModalBody, ModalHeader } from './bootstrap/Modal';
import { componentPagesMenu, dashboardPagesMenu } from '../menu';
import { DataContext } from '../contexts/UserDataContext';
import { Box, Layers, Truck } from 'react-feather';
import { Form, Row } from 'react-bootstrap';
import Modal, { ModalBody, ModalHeader } from './bootstrap/Modal';

import Spinner from './bootstrap/Spinner';
const deliverySearchParam = [
	'delivered_by',
	'date',
	'delivery_id',
	'status',
	'line_count',
	'order_count',
	'customer_name',
	'customer_id',
];

const orderSearchParams = [
	'id',
	'order_no',
	'date',
	'status',
	'order_name',
	'ref_no',
	'req_no',
	'order-type',
	'customer_name',
	'customer_id',
];

const pickupSearchParam = [
	'pickup_by',
	'date',
	'pickup_id',
	'status',
	'line_count',
	'order_count',
	'customer_id',
	'customer_name',
];
const Search = () => {
	const refSearchInput = useRef<HTMLInputElement>(null);
	const userDataContext = useContext(DataContext);
	const [results, setResults] = useState<any>({
		deliveries: [],
		orders: [],
		pickups: [],
	});
	const navigate = useNavigate();
	const [searchModalStatus, setSearchModalStatus] = useState(false);
	const formik = useFormik({
		initialValues: {
			searchInput: '',
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		onSubmit: (values) => {
			setSearchModalStatus(true);
		},
	});

	const searchItem = useCallback(
		(title: string, dataArray: any, columns: any[]) => {
			const searchResult: { [key: string]: any } = {};

			const filteredData = dataArray
				? dataArray.filter((data: any) => {
						let result = false;

						for (let i = 0; i < columns.length; i++) {
							if (
								String(data[columns[i]])
									.toLowerCase()
									.includes(formik.values.searchInput.toLowerCase())
							) {
								result = true;
							}
						}
						return result;
				  })
				: [];
			if (filteredData.length > 0) searchResult[title] = filteredData;

			return searchResult;
		},
		[formik.values.searchInput],
	);

	useEffect(() => {
		if (formik.values.searchInput) {
			setSearchModalStatus(true);
			refSearchInput?.current?.focus();
			setResults({
				...searchItem(
					'deliveries',
					userDataContext?.userData?.deliveries?.data,
					deliverySearchParam,
				),
				...searchItem('orders', userDataContext?.userData?.orders?.data, orderSearchParams),
				...searchItem(
					'pickups',
					userDataContext?.userData?.pickups?.data,
					pickupSearchParam,
				),
			});
		}

		return () => {
			setSearchModalStatus(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		formik.values.searchInput,
		userDataContext?.userData?.deliveries?.data,
		userDataContext?.userData?.orders?.data,
		userDataContext?.userData?.pickups?.data,
	]);

	const searchPages: {
		[key: string]: {
			id: string;
			text: string;
			path: string;
			icon: string;
		};
	} = {
		...dashboardPagesMenu,
	};
	const filterResult = Object.keys(searchPages)
		.filter(
			(key) =>
				searchPages[key].text
					.toString()
					.toLowerCase()
					.includes(formik.values.searchInput.toLowerCase()) ||
				searchPages[key].path
					.toString()
					.toLowerCase()
					.includes(formik.values.searchInput.toLowerCase()),
		)
		.map((i) => searchPages[i]);
	const resultLength =
		filterResult.length +
		(parseInt(results?.deliveries?.length) || 0) +
		(parseInt(results?.orders?.length) || 0) +
		(parseInt(results?.pickups?.length) || 0);
	return (
		<>
			<div className='d-flex ' data-tour='search'>
				<label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
					<Icon icon='Search' size='2x' color='primary' />
				</label>
				{/* <Form.Control */}
				<Form.Control
					id='searchInput'
					type='search'
					className='border-0 shadow-none bg-transparent'
					placeholder='Search...'
					onChange={formik.handleChange}
					value={formik.values.searchInput}
					autoComplete='off'
					// autoFocus
				/>
			</div>
			<Modal
				setIsOpen={setSearchModalStatus}
				isOpen={searchModalStatus}
				isStaticBackdrop
				isScrollable
				size={'lg'}
				data-tour='search-modal'>
				<ModalHeader setIsOpen={setSearchModalStatus}>
					<label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
						<Icon icon='Search' size='2x' color='primary' />
					</label>
					<Input
						ref={refSearchInput}
						name='searchInput'
						className='border-0 shadow-none bg-transparent'
						placeholder='Search...'
						onChange={formik.handleChange}
						value={formik.values.searchInput}
					/>
				</ModalHeader>
				<ModalBody>
					<caption>
						Results: <strong>{resultLength}</strong>
					</caption>
					{(userDataContext?.userData?.deliveries?.loading ||
						userDataContext?.userData?.orders?.loading ||
						userDataContext?.userData?.pickups?.loading) && (
						<Row className='justify-content-center mb-4'>
							<Spinner isGrow size={20} className='me-2' />
							<Spinner isSmall size={20} isGrow className='me-2' />
							<Spinner isSmall size={20} isGrow />
						</Row>
					)}
					{results?.deliveries?.length && (
						<table className='table table-hover table-modern mb-2 w-100'>
							<caption style={{ captionSide: 'top' }}>
								<p>
									<b>Deliveries</b>
								</p>
							</caption>

							<tbody>
								{results?.deliveries.map((item: any) => (
									<tr
										key={item.delivery_id}
										className='cursor-pointer p-2'
										onClick={() => {
											navigate(`/deliveries/${item.delivery_id}`);
											setSearchModalStatus(false);
											formik.resetForm();
										}}>
										<td>{item.delivery_id}</td>
										<td>{item.data?.date}</td>
										<td>{item?.data?.customer_name}</td>
										<td className='row justify-content-end'>
											{item?.data?.badge}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
					{results?.orders?.length && (
						<table className='table table-hover table-modern caption-top  mb-2 w-100'>
							<caption style={{ captionSide: 'top' }}>
								<p>
									<b>Orders</b>
								</p>
							</caption>
							<tbody>
								{results?.orders.map((item: any) => (
									<tr
										key={item.order_no}
										className='cursor-pointer'
										onClick={() => {
											navigate(`/orders/${item.order_no}`);
											setSearchModalStatus(false);
											formik.resetForm();
										}}>
										<td>{item.order_no}</td>
										<td className='row justify-content-end'>
											{item?.data?.badge}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
					{results?.pickups?.length && (
						<table className='table table-hover table-modern caption-top mb-2 w-100'>
							<caption style={{ captionSide: 'top' }}>
								<p>
									<b>Pickups</b>
								</p>
							</caption>
							<tbody>
								{results?.pickups.map((item: any) => (
									<tr
										key={item.pickup_id}
										className='cursor-pointer'
										onClick={() => {
											navigate(`/pickups/${item.pickup_id}`);
											setSearchModalStatus(false);
											formik.resetForm();
										}}>
										<td>{item.pickup_id}</td>
										<td>{item?.data?.customer_name}</td>
										<td className='row justify-content-end'>
											{item?.data?.badge}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
					<table className='table table-hover table-modern caption-top mb-0 w-100'>
						<caption style={{ captionSide: 'top' }}>
							<p>
								<b>Pages</b>
							</p>
						</caption>
						<tbody>
							{filterResult.length &&
								filterResult.map((item) => (
									<tr
										key={item.id}
										className='cursor-pointer'
										onClick={() => {
											navigate(`../${item.path}`);
											formik.resetForm();
										}}>
										<td>
											{item.icon && (
												<Icon
													icon={item.icon}
													size='lg'
													className='me-2'
													color='primary'
												/>
											)}
											{item.text}
										</td>
									</tr>
								))}
							{!resultLength && (
								<tr className='table-active'>
									<td>No result found for query "{formik.values.searchInput}"</td>
								</tr>
							)}
						</tbody>
					</table>
				</ModalBody>
			</Modal>
		</>
	);
};

export default Search;
