import {
	DocumentData,
	DocumentReference,
	Timestamp,
	collection,
	getDocs,
	orderBy,
	query,
	where,
} from 'firebase/firestore';
import React from 'react';
import CustomBadge, { getStatusEnums, RCStatusEnum, RCType } from '../components/CustomBadge';
import { RCTypeInfo } from '../contexts/UserDataContext';

export default class Order {
	order_no = '';

	delivery_pickup_datetime = new Timestamp(0, 0);

	id = '';

	order_type = '';

	order_name = '';

	ref_no = '';

	req_no = '';

	route_id = '';

	customer_id = '';

	customer_name = '';

	status = 0;

	route_datetime = new Timestamp(0, 0);

	constructor(order: RCTypeInfo['ORDER']) {
		this.order_no = order.order_no;
		this.id = order.id;
		this.delivery_pickup_datetime = order.delivery_pickup_datetime;
		this.order_name = order.order_name;
		this.order_type = order.order_type ? order.order_type.toLowerCase() : 'order';
		this.ref_no = order.ref_no;
		this.req_no = order.req_no;
		this.route_id = order.route_id;
		this.status = order.status;
		this.route_datetime = order.route_datetime;
		this.customer_name = order.customer_name;
		this.customer_id = order.customer_id;
	}

	get data(): any {
		const status = getStatusEnums(this.status).title[this.order_type];

		try {
			return {
				...this,
				order_type: this.order_type.toUpperCase(),
				status,
				badge: <CustomBadge type={RCType.DELIVERY} code={this.status} />,
				date: this.route_datetime.seconds
					? this.route_datetime.toDate().toDateString()
					: '. . .',
				time: this.route_datetime.seconds
					? this.route_datetime.toDate().toLocaleTimeString()
					: '. . .',
			};
		} catch (error) {
			return {
				...this,
				order_type: this.order_type.toUpperCase(),
				status,
				badge: <CustomBadge type={RCType.DELIVERY} code={this.status} />,
				date: '',
				time: '',
			};
		}
	}
	static fetchCustomerOrders = async (
		customerRef: DocumentReference<DocumentData, DocumentData>,
		fetchEstimatedDatetime: (tracking_ref: string) => Promise<Timestamp>,
	) => {
		const ordersQuery = query(
			collection(customerRef, 'orders'),
			where('order_no', '!=', 'NGWINTDATA'),
			orderBy('order_no', 'desc'),
		);

		const ordersCollection = await getDocs(ordersQuery);
		return await Promise.all(
			ordersCollection.docs.map(async (docSnapshot) => {
				const docData = docSnapshot.data();
				docData.order_no = docSnapshot.id;
				const { status, id } = docData;

				let routeDatetime: Timestamp;
				try {
					routeDatetime = new Timestamp(docData.delivery_datetime?.seconds, 0);
					if (
						![
							RCStatusEnum.ATTEMPTED,
							RCStatusEnum.DELIVERED,
							RCStatusEnum.PARTIAL,
							RCStatusEnum.REJECTED,
						].includes(status as number)
					) {
						routeDatetime = await fetchEstimatedDatetime(id);
						docData.delivery_pickup_datetime = routeDatetime;
					}
				} catch (error) {
					routeDatetime = new Timestamp(0, 0);
				}

				return new Order({
					...(docData as RCTypeInfo['ORDER']),
					route_datetime: docData.delivery_pickup_datetime,
				});
			}),
		);
	};
}
