/* eslint-disable react-hooks/exhaustive-deps */
import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useReducer,
	useState,
	FC,
	ReactNode,
	useCallback,
} from 'react';

import { FirebaseApp, initializeApp } from 'firebase/app';
import {
	getAuth,
	signInWithEmailAndPassword,
	signOut,
	setPersistence,
	indexedDBLocalPersistence,
	onAuthStateChanged,
	User,
} from 'firebase/auth';
import { getFirestore, Firestore } from 'firebase/firestore';
import { firebaseConfig } from '../config';
import showNotification from '../components/extras/showNotification';
import axios from 'axios';
import Modal, { ModalBody } from '../components/bootstrap/Modal';
import { ModalHeader } from 'react-bootstrap';
import Button from '../components/bootstrap/Button';

const INITIALIZE = 'INITIALIZE';

const initialState = {
	isAuthenticated: false,
	isInitialized: false,
	_user: null,
	username: '',
	stores: [],
};
type AuthState = {
	isAuthenticated?: boolean;
	isInitialized?: boolean;
	_user?: User | null;
	customer_id?: string;
	role?: string;
	username?: string;
	stores?: { id: string; name: string }[];
	timeoutId?: number;
};

const reducer: (state: AuthState, action: { type: string; payload: AuthState }) => AuthState = (
	state,
	action,
) => {
	switch (action.type) {
		case INITIALIZE:
			return {
				...state,
				isInitialized: true,
				...action.payload,
			};

		default:
			break;
	}

	return state;
};
export interface AuthContextType extends AuthState {
	method: string;
	user: {
		id: string | null;
		email: string | null;
		displayName: string | null;
		role: string | null;
		customer_id: string | null;
		username: string | null;
		stores?: { id: string; name: string }[];
	};
	signIn: (email: string, password: string, persistLogin: boolean) => Promise<void>;
	logOut: () => void;
	app: FirebaseApp;
	tenantDb: Firestore;
}

interface AuthProviderProps {
	children?: ReactNode;
}

const AuthContext = createContext<AuthContextType | null>(null);

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [showModal, setShowModal] = useState(false);
	const app = useMemo(() => {
		return initializeApp(firebaseConfig);
	}, []);
	const tenantDb = useMemo(() => {
		return getFirestore(app);
	}, [app]);

	const auth = getAuth();

	const signIn: (
		email: string,
		password: string,
		persistLogin: boolean,
	) => Promise<void> = async (email, password, persistLogin) => {
		window.localStorage.setItem('lastLoginAt', JSON.stringify(Date.now()));

		if (!persistLogin) {
			await signInWithEmailAndPassword(auth, email, password);
		} else {
			await setPersistence(auth, indexedDBLocalPersistence);
			await signInWithEmailAndPassword(auth, email, password);
		}
	};

	const logOut = async () => {
		await signOut(auth);
		dispatch({
			type: INITIALIZE,
			payload: { isAuthenticated: false, _user: null },
		});
	};
	useEffect(
		() =>
			onAuthStateChanged(auth, async (user) => {
				const lastLoginAt =
					JSON.parse(window.localStorage.getItem('lastLoginAt') as string) || Date.now();
				if (user) {
					const idTokenResult = await user.getIdTokenResult();
					const claims = idTokenResult.claims;

					if (lastLoginAt < Date.now() - 1800000) {
						await logOut();
					}
					if (
						!['CUSTOMER', 'CUSTOMERGROUP'].includes(claims.role as string) ||
						!(claims.routectrl_id as string)?.length
					) {
						showNotification(
							'Customer Login ',
							`${user.email} is not valid, check email and try again`,
							'danger',
						);
						await logOut();
						return;
					}

					axios.defaults.headers.common.Authorization =
						'Bearer ' + (await user.getIdToken());

					dispatch({
						type: INITIALIZE,
						payload: {
							isAuthenticated: true,
							_user: user,
							role: claims.role as string,
							customer_id: claims.routectrl_id as string,
							username: user?.displayName || '',
							stores: claims.stores as { id: string; name: string }[],
							timeoutId: window.setTimeout(setShowModal, 1800000, true),
						},
					});
				} else {
					dispatch({
						type: INITIALIZE,
						payload: { isAuthenticated: false, _user: null },
					});
				}
			}),

		[dispatch],
	);
	useEffect(() => {
		window.localStorage.setItem('lastLoginAt', JSON.stringify(Date.now()));
	}, []);

	return (
		<AuthContext.Provider
			value={{
				...state,
				method: 'firebase',
				user: {
					id: state._user?.uid || '',
					email: state._user?.email || '',
					displayName: state._user?.displayName || '',
					role: state.role || '',
					customer_id: state.customer_id || '',
					username: state.username || '',
					stores: state.stores,
				},
				signIn,
				logOut,
				app,
				tenantDb,
			}}>
			<Modal
				isOpen={showModal}
				isAnimation
				isCentered
				isStaticBackdrop
				setIsOpen={setShowModal}
				size={'sm'}>
				<ModalHeader>
					<b>Your session has expired. Please refresh to continue.</b>
				</ModalHeader>
				<ModalBody className='d-flex col justify-content-center'>
					<Button
						isOutline
						className='bg-primary text-white'
						onClick={() => window.location.reload()}>
						Refresh
					</Button>
				</ModalBody>
			</Modal>
			{children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider };
