import React, { useState, useContext, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DropdownItem, DropdownMenu } from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useAuth from '../../hooks/useAuth';
import { getFirstLetter } from '../../helpers/helpers';
import { CLEAR, DataContext } from '../../contexts/UserDataContext';

const User = () => {
	const userDataContext = useContext(DataContext);
	const { logOut, user } = useAuth();

	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);

	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<div
				className={classNames('user', { open: collapseStatus })}
				role='presentation'
				onClick={() => setCollapseStatus(!collapseStatus)}>
				{user?.username && (
					<div
						className='user-avatar d-flex justify-content-center align-items-center  text-white text-center  text-uppercase rounded-circle'
						style={{
							height: '35px',
							width: '35px',
							background: '#05050b',
						}}>
						{getFirstLetter(user?.username as string)}
					</div>
				)}
				<div className='user-info'>
					<div className='user-name d-flex align-items-center'>{`${user?.username}`}</div>
					{/* <div className='user-sub-title'> {user.role}</div> */}
				</div>
			</div>
			<DropdownMenu>
				<DropdownItem>
					<Button
						icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
						onClick={() => setDarkModeStatus(!darkModeStatus)}
						aria-label='Toggle fullscreen'>
						{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
					</Button>
				</DropdownItem>
			</DropdownMenu>

			<Collapse isOpen={collapseStatus} className='user-menu'>
				<NavigationLine />
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								window.localStorage.setItem('lastPath', '');
								userDataContext?.userDataDispatch({ type: CLEAR, payload: {} });
								logOut();
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text'>
										{t('menu:Logout') as ReactNode}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>
	);
};

export default User;
